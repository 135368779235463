<template>
    <div>
        <div class="vertFlex">
            <div class="imagediv" >
                <img alt="selfie image"  :src="capturedImageURL" class="studentimage" v-if="capturedImageURL!=null"/>
                <div class="editdiv">
                    <smallbutton toplabel="Edit Picture" buttonclass="editbutton" @click="$refs.imgedit.openModal()" v-if="havePic"/>
                </div>
            </div>

            <!-- confirmation of terms -->
            <div v-if="!confirmedPicture && confirmRequirements">
                <button class="pbutton2" @click="$refs.confirmpic.openModal()" >
                    Click to Confirm Picture Requirements      
                </button>
            </div> 
      
            <div >
                <imagecapture :captureText="captureText" buttonClass="pbutton" :allowFileSelect="allowFileSelect" :allowFileSelectIfNoCapture='allowFileSelectIfNoCapture' @gotimage="gotImage"  :showImage='showImage' v-if="confirmedPicture || !confirmRequirements" />
            </div>
        </div>
        <div>
            <tallbutton toplabel="Continue" @click="continueAfterPicture()" />
            <!-- :disabled="!havePic && requirePicture" -->
        </div> 
        <div v-if="confirmRequirements">
            <hr>
            <label class="titlelabel">Picture Requirements</label>
            <br>
            <label v-html="photoRequirements" class="photoreq" />
        </div> 
      
        <confirmpic ref="confirmpic" v-on:accept="confirmPictureRequirements()" :photoRequirements="photoRequirements"/>
        <imageeditor ref="imgedit" :img="capturedImageURL" @save="saveedits"/>
    </div>
  
           
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
import smallbutton from '../controls/button.vue'
import confirmpic from '../actions/confirmpic.vue'
import imagecapture from '../controls/ImageCapture.vue'
import imageeditor from '../controls/ImageEditor.vue' 
export default {
    name:'getpicture',
    mixins:[datamethods],
    components:{
        tallbutton,
        smallbutton,
        confirmpic,
        imagecapture,
        imageeditor,
    },
    data(){
        return{
       
            confirmedPicture:false,
            capturedImage:null,
            lastCapturedImageURL:null,

            photoRequirements:''
        }
    },
    props:{
        confirmRequirements:{
            type:Boolean,
            default:false
            
        },
        allowFileSelect:{
          type:Boolean,
          default:false
        },
        allowFileSelectIfNoCapture:{
          type:Boolean,
          default:false
        },
        allowCapture:{
            type:Boolean,
            default:true
        },
        useRearCamera:{
            type:Boolean,
            default:false
        },
        selectedImageClass:{
          type:String,
          default:'selectedImage'
        },
        buttonClass:{
          type:String,
          default:'captureButton'
        },
        buttonLabelClass:{
          type:String,
          default:'buttonlabel'
        },
        pleaseWaitMessage:{
          type:String,
          default:'Please Wait...'
        },
        fileSelectText:{
          type:String,
          default:'Upload File'
        },
        captureText:{
          type:String,
          default:'Take Picture'
        },
        webCamCaptureText:{
          type:String,
          default:'Capture Photo'
        },
        showDeviceInfo:{
          type:Boolean,
          default:false
        },
        showImage:{
          type:Boolean,
          default:true
        },
        fileAccept:{
          type:String,
          default:'image/*'
        },
        imageStorageName:{
            type:String,
            require:true
        },
        defaultImageName:{
            type:String,
            require:true
        },
        requirePicture:{
            type:Boolean,
            default:true,
        },
        havePic:{
            type:Boolean
        }
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'message',
            'pleaseWait'
        ]),
        capturedImageURL(){
            if(this.lastCapturedImageURL==null){
                return this.defaultImageName
            }else{
                return this.lastCapturedImageURL
            }
        },
       
       
    },
    methods:{
        gotImage(file,url){
            this.capturedImage=file
            this.lastCapturedImageURL=url
            this.storeImage(this.imageStorageName, file, file.name,this.imageStorageName)
            this.$emit('havepic')
        },
        saveedits(file, url){
            this.capturedImage=file
            this.lastCapturedImageURL=url
            this.storeImage(this.imageStorageName, file, file.name,this.imageStorageName)
        },
        async storeImage(filename,file, name,type){
             
            // var bId=-1
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('badgeId',this.loggedInUser.badgeId);
            postParams.append('personId',this.loggedInUser.user_personId);
            postParams.append('type',type);
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI_Image(postParams,'saveimage')
            //handle response
            if(response.Successful){
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false
        },
   
        confirmPictureRequirements(){
            this.confirmedPicture=true
    
        },
        continueAfterPicture(){
            if(!this.havePic && this.requirePicture){
                this.message = "This picture is required."
                return
            }
            this.$emit('gotpic',this.capturedImageURL)
            this.lastCapturedImageURL=null
        },
        async getPhotoReq(){
            let req = {
                locationId:-1
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI_Image(req,'photoreq')

            //handle response
            if(response.Successful){
                this.photoRequirements = response.StringResponse
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        /* setDefaultImage(){
            console.log('set def pic ' + this.defaultImageName)
            this.capturedImageURL=require('@/assets/' + this.defaultImageName)
            this.capturedImage=null
            this.havePic=false
        } */
    },
    mounted(){
        
        if(this.confirmRequirements){
            this.getPhotoReq();
        }
        //this.setDefaultImage();
    }
    
}
</script>

<style>
.imagediv{
  border: 2px solid var(--button-color);
  background-color:white;
  display:flex;
  flex-direction: column;
  align-items: center;
  width:40%;
  margin:auto;
}
.editbutton{
  margin:0px;
  background-color:var(--button-color);
  color:white;
  border:none;
  padding:5px;
}
.editdiv{
  display:flex;
  width:100%;
  flex-direction: row-reverse;
}

.photoreq{
  font-size:small;
}
.studentimage{
  margin:10px;
  max-width:90%;
}
.pbutton{
    background-color:var(--button-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    
    font-size: medium;
    margin:10px;

  text-decoration: none;
}
.pbutton2{
    background-color:var(--button-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    width:200px;
    height:60px;
    padding:0px 0px 0px 0px;
    font-size: medium;
    margin:10px;

  text-decoration: none;
}
</style>