import { createStore } from 'vuex'
import {getField, updateField} from 'vuex-map-fields'

export const store = createStore({
    state:{
        //general
        securityAppName:'StudentHome',
        username:'',
        oldpassword:'',
        password:'',
        loggedInUser:{
            name:'User',
            isAdmin:false,
            walletId:0,
            user_accountId:0,
            user_personId:0,
            badgeId:0,
            LocationId:0
        },
        loggedIn:false,
        showHeader:true,
        robot:true,
        
        lastRefreshDateTime_Wallet:'',
        lastRefreshDateTime_Explorer:'',

        //wallet
        currentWallet:{},
        currentNav:'',
        photoRequirements:'',
        logoOverride:'',
        logoImageBasePath:'',
        requireLogin:true,
        
        pleaseWait:false,
        message:'',

        //url product selection
        isUrlProduct:false,
        selectedLocation:'',
        selectedProgram:'',
        selectedDate:'',
        selectedTime:'',
        selectedCost:0,
        selectedDeposit:0,

        //account product
        isAccountProduct:false,
        accountList:[],
        selectedAccount:{},
        
        //
        accountTransactionId:-1,
        selectedPaymentAmount:0,

        person:{},
        newPerson:{
            ID:0,
            FirstName:'',
            LastName:'',
            PhoneNumber1:'',
            EmailAddress:'',
            AddressLine1:'',
            AddressLine2:'',
            City:'',
            StateID:-1,
            ZipCode:'',
            referredBy:''
          },
          
        selectedProductGUID:null,
        selectedProductId:-1
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.message=''
        },
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})