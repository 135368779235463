<template>
    <div class="vertFlex" >
       
        <div class="vertFlex">
            <div >
                <div class="bordered" v-for="account in accountList" :key="account.ID" :value="account.ID">
                      <div class="vertFlexTop">
                        <label for="" class="subtitleBold">
                            {{account.Name}} 
                        </label>
                       <!--    <label class="listtextbold">
                            Amount Due: {{toCurrency(account.amountDue)}}
                        </label> -->
                        <label for="" class="listtextbold" v-if="account.optInForAccountOption">
                            {{accountOptionViewLabel}}
                        </label>
                        <label class="listtextbold" v-if="account.FeeAmount!='0'">
                            Service Fee: {{toCurrency(account.FeeAmount)}}
                        </label>
                        <tallbutton :bottomlabel="payText(account)" toplabel="Make Payment"@click="confirm(account)" v-if="account.amountDue>0"/>
                            <label for="" class="subtitleBold redtext" v-if="showNoDue(account)">Our records show no amount due.</label>
                      </div>  
                  </div>
                  <div class="bordered" v-if="accountList.length==0">
                      <label for="" class="subtitleBold">There are no accounts available to pay</label>
                  </div>
            </div>
        </div>
        <div>
            
        </div> 
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
import format from '../../style/format.js'
export default {
    name:'accountproduct',
    components:{
        tallbutton
    },
    mixins:[datamethods,format],
     props:{
         accountTransactionId:{
             type:Number,
         },
       
        confirmText:{
            type:String,
            default:"Continue"
        },
        skipIfNull:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',
            'loggedInUser',
            'accountList',
            'selectedAccount'
         
           
        ]),
        
    },
    data(){
        return{
            
        }
     },
    methods:{
        showNoDue(account){
            return !Number(account.amountDue)>0
        },
        toCurrency(amt){
            return this.$options.filters.toCurrency(amt)
        },
        payText(account){
            return "Amount Due " + this.$options.filters.toCurrency(account.amountDue) 
        },
        confirm(account){
            this.selectedAccount=account
            this.$emit('continue',account.amountDue)
        },
        async GetAccounts(){
            let amt = this.selectedPaymentAmount;
            let token = this.selectedWalletTokenId;

            //validate
            if(this.loggedInUser.user_personId==0){return;}
            //create req
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'account')
            //handle response
            if(response.Successful){
                this.accountList=response.AccountList;
                this.accountList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    if(arr[index].Amount==undefined){arr[index].Amount=amt}
                    arr[index].amountDue=Number(arr[index].amountDue).toFixed(2)
                    arr[index].TokenID=token
                    if(arr[index].FeeAmount==undefined){arr[index].FeeAmount='0'}
                }   
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
    },
    mounted(){
        this.GetAccounts()
    }
}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
.productinfo{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:1fr 1fr 1fr;
    text-align: left;
}
</style>