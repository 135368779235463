<template>
    <div class="vertFlex" >
       
      
            <tallbutton toplabel="I'm paying for my training" @click="self()"/>
            <tallbutton toplabel="Someone else is paying for my training" @click="thirdparty()"/>

       
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'payer',
    components:{
        tallbutton
    },
    mixins:[],
     props:{
        
 
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',

        ]),
        
    },
    data(){
        return{
 
        }
    },
    methods:{
         
        self(){
            this.$emit('self')
        },
        thirdparty(){
            this.$emit('thirdparty')
        },
     

    },
    mounted(){
    }
    

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
</style>