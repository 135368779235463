import { createApp } from 'vue'
import App from './App.vue'
import {store} from './store/store'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App)


app.use(store)
app.use(VueReCaptcha, { siteKey: '6LfnKzMfAAAAABMt1ZO3dHdXHyJv45GMPTgaDt2X' })
app.mount('#app')