<template>
  <div class="outerDiv" :style="configStyle">
      <svheader @logout="logout()" class="header"/>
      
      <div class="mainDiv">
        <div v-if="wasUpdated">
            <p class="update">Updates are available for your application. Please click Refresh to download the latest version.</p>
            <button @click="refreshapp()">Refresh</button>
        </div>
          <div  class="titlepanelfoot" >

              <div >
                  <div>
                      <h1><label class="title">{{title}}</label></h1>
                  </div>
                  
                  <label class="subtitleMain" v-if="subtitle!=''">{{subtitle}}</label>
                  <label class="subtitleMain"  v-if="subtitle2!=''">{{subtitle2}}</label>
              </div>
              
              
              <div class="panel">
                    <div v-if="pleaseWait">
                      <label class="pleasewait">Please Wait...</label>
                  </div>
                  <home v-if="currentPanel=='home'" @signup="startsignup" @pay="startpay" @docs="startdocs"/>
                  <payer v-if="currentPanel=='payer'" @self="selectpayer(true)" @thirdparty="selectpayer(false)" />
                  <promocode v-if="currentPanel=='promo'" @approved="approved"  />
                  <loginuser  ref='login' v-if="currentPanel=='login'" @login="afterlogin"/>
                  <location v-if="currentPanel=='location'" @select="selectLocation"/>
                  <accountproduct v-if="currentPanel=='accountproduct'" @continue="continueAccountProduct"/>
                  <producttype v-if="currentPanel=='producttype'" @select="selectproducttype"/>
                  <product ref="productscreen" v-if="currentPanel=='product'" :typeGUID="selectedProductTypeGuid" @select="selectproduct"/>
                  <initialconfirm v-if="currentPanel=='initialconfirm'"  @confirm="confirmtrans" :skipIfNull="paramTrue"/>
                  <personwallet v-if="currentPanel=='person'" :requireAddress="requireAddress" :requireEmail="requireEmail" 
                          :requireName="requireName" :requirePassword="requirePassword" :requirePhone="requirePhone" :addBadge="addBadge"
                          :requireBirthdate="requireBirthdate" :requireID="requireID"  @saved="saveperson"/>

                  <getpicture ref="getpicture_person" v-if="currentPanel=='picture'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                      :showImage="paramFalse" @gotpic="gotImage_person" :confirmRequirements="paramTrue" :havePic="havepic_person" 
                      :imageStorageName="storageName_person" :defaultImageName="pictureurl_person" :requirePicture="paramTrue" @havepic="pic_person"/> 
                  <getpicture ref="getpicture_dlf" v-if="currentPanel=='picture_dlf'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                      :showImage="paramFalse" @gotpic="gotImage_dlf" :confirmRequirements="paramFalse"  :havePic="havepic_dlf" 
                      :imageStorageName="storageName_dlf" :defaultImageName="pictureurl_dlf" :requirePicture="requireDL"  @havepic="pic_dlf"/> 
                  <getpicture ref="getpicture_dlb" v-if="currentPanel=='picture_dlb'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                      :showImage="paramFalse" @gotpic="gotImage_dlb" :confirmRequirements="paramFalse"  :havePic="havepic_dlb" 
                      :imageStorageName="storageName_dlb" :defaultImageName="pictureurl_dlb" :requirePicture="requireDL_Back"  @havepic="pic_dlb"/> 
                  <multipay ref="multipayscreen" v-if="currentPanel=='multipay'" @complete="fundssubmitted"  @altpayment="altpayment"/>
                  <accountsummary v-if="currentPanel=='accountsummary'" :accountTransactionId="accountTransactionId" 
                      :selectedPaymentAmount="selectedPaymentAmount" :selectedWalletTokenId="selectedWalletTokenId" @fundssubmitted="fundssubmitted"/>
                  <initialconfirm v-if="currentPanel=='thanks'" @nextsteps="nextsteps" :showNextSteps="showNextSteps" @confirm="confirmthanks" :confirmText="confirmThanksText" :skipIfNull="paramFalse" />
                  <nextsteps v-if="currentPanel=='nextsteps'" @confirm="confirmnextsteps"  :confirmText="confirmThanksText"/>
                  
              </div>
              
              <div>
                    <linkbutton class="backButton" v-if="showBack && !pleaseWait" @click="goBack()" :disabled="pleaseWait" toplabel="< Back"/>
              </div>
          </div>
          <div v-if="!message==''" class="vertFlex">
              <label class="responsemessage">{{message}}</label>
              <tallbutton toplabel="Click Here to Try Again" @click="tryAgain" v-if="message=='Your transaction failed to process.'"></tallbutton>
          </div>
      </div>

      <svfooter/>
      <updatepassword ref="changepass" v-show="ischangepassword" :username="username" :oldpassword="oldpassword"></updatepassword>
      <GlobalEvents v-if="listenersConnected" target="window" @focus="refresh"/>
  </div>
</template>

<script>
import svheader from './header.vue'
import svfooter from './footer.vue'
import {mapFields} from 'vuex-map-fields'
import datamethods from '../services/datamethods.js'
import format from '../style/format.js'
import style from '../style/style.js'
import loginuser from './actions/loginuser.vue'
import linkbutton from './controls/linkbutton.vue'
import home from './screens/home.vue'
import location from './screens/location.vue'
import producttype from './screens/producttype.vue'
import product from './screens/product.vue'
import initialconfirm from './screens/initialconfirm.vue'
import personwallet from './screens/person.vue'
import getpicture from './screens/picture.vue'
import multipay from './screens/multipay.vue'
import accountsummary from './screens/accountsummary.vue'
import accountproduct from './screens/accountProduct.vue'
import tallbutton from './controls/tallbutton.vue'
import nextsteps from './screens/nextsteps.vue'
import {GlobalEvents} from 'vue-global-events'
import payer from './screens/payer.vue'
import promocode from './screens/promocode.vue'
import updatepassword from './actions/updatepassword.vue'
export default {
  
  name: 'Start',
  props: {
    msg: String
  },
  components:{
        svheader,
        svfooter,
        loginuser,
        linkbutton,
        home,
        location,
        producttype,
        product,
        initialconfirm,
        personwallet,
        getpicture,

        multipay,
        accountsummary,
        accountproduct,
        tallbutton,
        nextsteps,
        payer,
        promocode,
        GlobalEvents,
        updatepassword
    },
    mixins:[datamethods, format,style],
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'message',
            'pleaseWait',
            'robot',
            'logoOverride',
            'logoImageBasePath',
            'username',
            'oldpassword',
            'selectedLocation',
            'isAccountProduct',
            'accountList',
            'selectedAccount',
            'accountTransactionId',
            'selectedPaymentAmount',
            'selectedProductGUID',
            'selectedProductId',
            'person',
            'newPerson',
            'wasUpdated'
        ]),
        showNextSteps(){
            if(window.SHOW_NEXT_STEPS==undefined){return true}
            else{return window.SHOW_NEXT_STEPS}
        },
        allowFileSelect(){
            if(window.ALWAYS_ALLOW_FILE_SELECT==undefined){return false}
            else{return window.ALWAYS_ALLOW_FILE_SELECT }
        },
        showPersonData(){
            if(window.SHOW_PERSON==undefined){return true}
            else{return window.SHOW_PERSON}
        },
        showGetDL(){
            if(window.SHOW_GETDL==undefined){return false}
            else{return window.SHOW_GETDL }
        },
        showGetDL_Back(){
            if(window.SHOW_GETDL_BACK==undefined){return false}
            else{return window.SHOW_GETDL_BACK}
        },
        requireDL(){
            if(window.REQUIRE_DL==undefined){return false}
            else{return window.REQUIRE_DL}
        },
        requireDL_Back(){
            if(window.REQUIRE_DL_BACK==undefined){return false}
            else{return window.REQUIRE_DL_BACK}
        },
        //images
        pictureurl_person(){
            if(this.url_person!=null){return this.url_person}
            else{return this.defaultImageName_person}
        }, 
         pictureurl_dlf(){
            if(this.url_dlf!=null){return this.url_dlf}
            else{return this.defaultImageName_dlf}
        },
        pictureurl_dlb(){
            if(this.url_dlb!=null){return this.url_dlb}
            else{return this.defaultImageName_dlb}
        },
        
        allowThirdPartyPayment(){
          if(window.ALLOW_THIRDPARTY_PAYMENT==undefined){return false}
          else{return window.ALLOW_THIRDPARTY_PAYMENT }
        },
        requireThirdPartyPromoCode(){
          if(window.REQUIRE_THIRDPARTY_PROMO==undefined){return false}
          else{return window.REQUIRE_THIRDPARTY_PROMO }
        }
          
    },
    data(){
        return{
            ischangepassword:false,
            listenersConnected:true,

            //state
            currentPanel:'home',
            previousPanel:'home',
            panelList:[],
            title:'',
            subtitle:'',
            subtitle2:'',
            showBack:false,

            //sel product, token
            selectedProductTypeGuid:null,
            selectedWalletTokenId:null,
            payself:true,


            //show and require
            requireAddress:false,
            requirePhone:false,
            requireEmail:false,
            requireName:false,
            requirePassword:false,
            requireBirthdate:false,
            requireID:false,
            showPicture:false,
            showPayment:false,
            addBadge:false,

            //true false for comp params
            paramTrue:true,
            paramFalse:false,


            //images
            url_person:null,
            url_dlf:null,
            url_dlb:null,
            havepic_person:false,
            havepic_dlf:false,
            havepic_dlb:false, 
            storageName_person:'person',
            defaultImageName_person:require('@/assets/' + 'WM_personimage.png'), 
            storageName_dlf:'front',
            defaultImageName_dlf:require('@/assets/' + 'WM_licensefront.png'),
            storageName_dlb:'back',
            defaultImageName_dlb:require('@/assets/' + 'WM_licenseback.png'),

            

        }
    },
    methods:{
        refreshapp(){
            window.location.reload(true);
        },
      reset(){
        console.log('reset data')
            this.url_person=null
            this.url_dlf=null
            this.url_dlb=null
            this.havepic_person=false
            this.havepic_dlf=false
            this.havepic_dlb=false 

            this.currentPanel='home'
            this.previousPanel='home'
            this.panelList=[]
    

            //sel product, token
            this.selectedProductTypeGuid=null
            this.selectedProductGUID=null
            this.selectedWalletTokenId=null
            this.payself=true
            this.isAccountProduct=false

            this.loggedInUser={}
            this.person=this.newPerson

            this.accountTransactionId=-1
            this.selectedPaymentAmount=0
            this.selectedAccount={}
   
      },
      addToPanelList(panel){
        this.panelList.push(panel)
      },
      getLastPanelFromList(){
        var panel = this.panelList.pop()
        return panel
      },
      GoTo(screen,previousScreen){
        
            console.log('goto ' + screen)
            if(previousScreen!=undefined) {
                this.addToPanelList(previousScreen)
                this.previousPanel=previousScreen;}
            

            this.currentPanel=screen;
            this.subtitle=''
            this.subtitle2=''
            this.showBack=true;
          
            switch (screen) {
                case 'home':
                    this.title="Student Home"
                    this.showBack=false
                    break;
                case 'login':
                    this.title="Login"
                    break;
                case 'payer':
                  if(!this.allowThirdPartyPayment){
                    this.GoTo('location','home')
                    return;
                  }

                  this.title="Who is Paying"
                  break;
                case 'promo':
                  if(!this.requireThirdPartyPromoCode){
                    this.GoTo('location','home')
                    return;
                  }

                  this.title="Enter Sponsor Code"
                  break;
                case 'location':
                    this.title='Select Location'
                    break;
                case 'accountproduct':
                    this.title="Pay My Account"
                    break;
                case 'producttype':
                    this.title='Product Type'
                    this.subtitle='Select a type of product from the list below'
                    break;
                case 'product':
                    //skip condition

                    this.title='Product'
                    this.subtitle='Select a product from the list below'
                    break;
                case 'initialconfirm':
                    this.title='Confirm Transaction'
                    this.subtitle='Please confirm your information and the names of your accounts.'
                    break;
                case 'person':
                    this.title='Enter your Information'
                    break;
                case 'picture':
                    //skip condition
                    if(!this.showPicture){
                        this.GoTo('multipay')
                        return;
                    }
                    this.title='ID Picture'
                    break;
                case 'picture_dlf':
                    //skip condition
                    if(!this.showGetDL){
                        this.GoTo('multipay')
                        return;
                    }
                    this.title="Driver's License Front"
                    break;
                case 'picture_dlb':
                    //skip condition
                    if(!this.showGetDL_Back){
                        this.GoTo('multipay')
                        return;
                    }
                    //titles
                    this.title="Driver's License Back"

                    break;
                case 'multipay':
                    //skip condition
                    if(!this.showPayment){
                        this.complete_sendMessages()
                        this.GoTo('thanks')
                        return;
                    }
                    if(!this.payself){
                        this.complete_sendMessages()
                      this.showPayment=false
                      this.GoTo('thanks')
                      return;
                    }
                    this.title="Add Payments"

                    if(!this.isAccountProduct){
                        this.$nextTick(()=>{
                            this.$refs.multipayscreen.initializeEnrollment()
                        });

                    }else{
                        this.$nextTick(()=>{
                            this.$refs.multipayscreen.payExisting()
                        });
                        
                    }
                    
                    break;
                case 'accountsummary':
                    this.title="Purchase Confirmation"
                    this.subtitle='Please review all of your information.'
                    break;
                case 'thanks':
                    if(this.showPayment){
                        this.title='Thank you for your payment!'
                        this.subtitle=''
                        if(window.THANKS_MESSAGE_OVERRIDE!=null){
                            if(window.THANKS_MESSAGE_OVERRIDE!=''){
                                this.subtitle=window.THANKS_MESSAGE_OVERRIDE   
                            }
                        }
                    }else if(this.payself==false){
                         //need to finish up, if no pmt
                         this.CompleteNoPay();

                        this.title='Thank you!'
                        this.subtitle='Thanks for signing up for class!'
                        
                    
                    }else{
                        //need to finish up, if no pmt
                        this.CompleteNoPay();

                        this.title='Thank you!'
                        this.subtitle='You will receive an email confirmation shortly.'
                        if(window.THANKS_MESSAGE_OVERRIDE_NOPAYMENT!=null){
                            if(window.THANKS_MESSAGE_OVERRIDE_NOPAYMENT!=''){
                                this.subtitle=window.THANKS_MESSAGE_OVERRIDE_NOPAYMENT   
                            }
                        }
                    }
                    this.showBack=false
                    break;
                case 'nextsteps':
                    if(this.isAccountProduct){
                        this.logout()
                        return
                    }
                    this.title='Next Steps'
                    this.subtitle=''
                    this.showBack=false    
                    break;
                default:
                    break;
            }
        },
      goBack(){
            var panel=this.getLastPanelFromList()
          console.log('go back ' +panel)
          if(panel!=null){
            this.GoTo(panel);
          }
          

        },

        logmein(){
            if(this.isAccountProduct){
                this.saveperson()
            }else{
                if(this.loggedInUser.ProductTypeKey_Default!=null){
                    this.previousPanel='producttype'
                    this.selectedProductTypeGuid=this.loggedInUser.ProductTypeKey_Default
                    console.log('settype ' + this.selectedProductTypeGuid)
                    this.GoTo('product')
                }else
                {
                    this.GoTo('producttype')
                }
                
            }

        },
        startsignup(){
            this.reset()
          this.GoTo('payer','home')
        },
        startpay(){
            this.reset()
          this.isAccountProduct=true
          this.GoTo('login','home')
        },
        afterlogin(){
          this.GoTo('accountproduct','login')
        },
        startdocs(){
          let url = window.MOBILEID_URL
            if(url!=undefined){
                url = url + '?EmailAddress=' + this.username
                //window.location.href = url
                window.open(url, '_blank').focus();
            }
        },
        selectpayer(isself){
          this.payself=isself
          if(!isself){
            this.GoTo('promo','payer')
          }else{
            this.GoTo('location','payer')
          }
          
        },
        approved(){
          this.GoTo('location','payer')
        },
        selectLocation(loc){
            this.loggedInUser.LocationId=loc.id
            this.GoTo('producttype','location')
        },
        selectproducttype(productTypeGuid){
            this.selectedProductTypeGuid = productTypeGuid
            this.GoTo('product','producttype')
        },
        async selectproduct(product,accountTransactionId){
            this.selectedProductGUID = product.guid
            this.selectedProductId=product.id
            //handle the requirements for selected product
            this.requireAddress=product.requireAddress 
            this.requirePhone=product.requirePhone 
            this.requireEmail=product.requireEmail 
            this.requireName=product.requireName 
            this.showPicture=product.requirePicture 
            this.requirePassword=product.requirePassword
            this.requireBirthdate=product.requiresBirthdate
            this.requireID=product.requiresID
            this.showPayment=product.price>0 ? true:false
            this.selectedPaymentAmount=product.price
            this.addBadge = product.isBadgeProduct
            this.accountTransactionId=accountTransactionId
            //this.GoTo('initialconfirm','product')
            this.GoTo('person','product')
        },
        
        continueAccountProduct(amtdue){
            this.selectedPaymentAmount=amtdue
            this.showPayment=true
            this.GoTo('multipay','accountproduct')
        },
        confirmtrans(){
            this.GoTo('person','product')
        },
        saveperson(){
            if(this.isAccountProduct){this.GoTo('accountproduct','person')}
            else{this.GoTo('picture','person')}
        },

        //images
        pic_person(){
            this.havepic_person=true
        }, 
         pic_dlf(){
            this.havepic_dlf=true
        },
        pic_dlb(){
            this.havepic_dlb=true
        },
        gotImage_person(url){
             this.url_person=url
            this.GoTo('picture_dlf','picture_person')
        },
        gotImage_dlf(url){
            this.url_dlf=url
            this.GoTo('picture_dlb','picture_dlf')
        },
        gotImage_dlb(url){
            this.url_dlb=url
            this.GoTo('multipay','picture_dlb')
        },
        selectcard(tokenid){
            this.selectedWalletTokenId=tokenid
            this.GoTo('accountsummary','card')
        },
        altpayment(){
            //this.complete_sendMessages() these are handled inside the api
            this.showPayment=false
            this.GoTo('thanks')
        },
        fundssubmitted(amt){
            this.complete_sendMessages(amt)
            this.GoTo('thanks')
        },
        nextsteps(){
            this.GoTo('nextsteps')
        },
        confirmthanks(){
           // this.startdocs()
            this.logout()
        },
        confirmnextsteps(){
            this.startdocs()
            this.logout()
        },
        logout(){
            console.log('logout')
            this.loggedIn=false
            this.reset()
            this.GoTo('home')
        },

       
        async getLogoImageBasePath(){
            //call api
            var response = await this.callAPI_Image(null,'imagepath')
            //handle response
            if(response.Successful){
                this.logoImageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        },
        async refresh(){
            console.log('refresh')
            if(this.currentPanel=='multipay'){
                this.$refs.multipayscreen.getList()
            }
        },
        //for teh preselect of product from url
        async getProductbyGUID(){
            let req = {
                walletId:this.loggedInUser.walletId,
                locationId:this.loggedInUser.LocationId,
                productGUID:this.selectedProductGUID
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'products')

            //handle response
            if(response.Successful){
                this.productList = response.productList
                if(this.productList.length>0){

                    this.select(response.productList[0])
                }else{
                    //didn't work, don't go on to product
                    this.selectedProductGUID=null
                    this.GoTo(this.currentPanel)
                }
            
            }else{
                //didn't work, don't go on to product
                this.message = response.Message 
                this.selectedProductGUID=null
                this.GoTo(this.currentPanel)
            }
            this.pleaseWait=false;
        },
        async select(product){
            
            //send in to start transaction
            let req = {
                walletId:this.loggedInUser.walletId,
                accountId:this.loggedInUser.user_accountId,
                locationId:this.loggedInUser.LocationId,
                restrictToProductTypeGlobalKey:this.typeGUID,
                restrictToProductGlobalKey:product.guid,
                price:product.price,
                
            }
            if(this.selectedLocation!=null){
                req.locationName=this.selectedLocation
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'productsale')

            //handle response
            if(response.Successful){
              
                this.selectproduct(product,response.ResponseInteger)
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        },
        
        async CompleteNoPay(){
          console.log('CompleteNoPay')
            //setup request
            let req = {
                walletId:this.loggedInUser.walletId,
                accountId:this.loggedInUser.user_accountId,
                initialAccountFundList:this.accountList,
                accountTransactionId:this.accountTransactionId,
                locationId:this.loggedInUser.locationId
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response
            response = await this.callAPI(req,'paymenttoaccountlist_product_zeroPayment')
            
            //handle response
            if(response.Successful){
    
            }else{
                this.message="Your transaction failed to process."
            }
            this.pleaseWait=false;
        },
        async complete_sendMessages(amt=0){
          console.log('complete_sendMessages')
            //setup request
            let req = {
                accountId:this.selectedAccount.ID,
                amount:amt
            }

            //if its a pmt, not new enroll, then set the subject flag
            if(this.isAccountProduct){
                req.messageSubject='New Payment for '
            }

            //call api
            this.$store.dispatch('beforeAPI');
            var response
            response = await this.callAPI(req,'accountmessages')
            
            //handle response
            if(response.Successful){
    
            }else{
                
            }
            this.pleaseWait=false;
        },
        receiveMessage(event){
            try{
                var msg =  JSON.parse(event.data)
            }
            catch(err){
                return
            }
            
            var action = msg.action
            if(action !=undefined){
            
                switch (action) {
                    case 'refresh':
 
                        break;
                    case 'appupdate':
                        window.location.reload(true);
                    default:
                        break;
                }
                
            }
        },

        async checkLastUpdate(){
            var needsClear =false

            //get the last update value from config
            var lastUpdate = '' 
            //if(window.LAST_UPDATE_DATE!=undefined){lastUpdate=window.LAST_UPDATE_DATE}

            //from api
            try {
            var response = await this.callAPI(null,'lastupdatedate')
                //handle response
                if(response.Successful){
                lastUpdate = response.StringResponse
    
                }

            } catch (error) {
            
            }


        
            //if we don't have a setting, then don't do anything
            if(lastUpdate==''){return}


            //get last update value from localstorage
            var storedLastUpdate = ''
            storedLastUpdate=localStorage.getItem('lastUpdate')

            console.log('lastupdate ' + lastUpdate + ' ' + storedLastUpdate)

            if(storedLastUpdate==undefined){needsClear=true}
            else if(storedLastUpdate!=lastUpdate){needsClear=true}

            //if we need to clear...
            if(needsClear){

                //set flag in store
                this.wasUpdated=true
            }
            
            //store the last update
            localStorage.setItem('lastUpdate',lastUpdate)
            //console.log('writing last update to local storage')
        }

    },
    mounted(){
        window.addEventListener('message', this.receiveMessage)
        this.setColors()
        //change password
        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('email')
            if(tmp!=null){
                this.username = tmp;
            }

            let tmp2 = urlParams.get('temp')
            if(tmp2!=null){
                this.oldpassword = tmp2;
                this.$nextTick(()=>{
                    //this.changepass()
                    this.ischangepassword=true
                    this.$refs.changepass.ChangePassword();
                });
            }

        this.checkLastUpdate()    
        this.GoTo('home')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/stylebase.scss";

@media screen and (min-width: 812px) {
    .outerDiv{
        background-color:var(--background-color);
        margin:0px 280px 0px 280px;
        height:98vh;
        display:grid;
        grid-template-columns:1fr 4fr 1fr;
        grid-template-rows:150px auto auto;
        grid-template-areas: 
            "header header header"
            "center center center"
            "footer footer footer";
    }
    .entry{
        flex-direction: row;
    }
    .title{
        font-weight: bold;
        font-size:xx-large;
    }
    .productButton{
      font-size: large;
      width:360px;
    }
    
    
    .headertext{
      font-size:medium;
      margin:2px;
    }
    .headertextbold{
      font-size:medium;
      font-weight:bold;
      margin:2px;
    }
}
/* phone */
@media screen and (max-width: 812px) {
    .outerDiv{
        background-color:var(--background-color);
        margin:0px 20px 0px 20px;
        height:100vh;
        display:grid;
        grid-template-columns:1fr 4fr 1fr;
        grid-template-rows:150px auto auto;
        grid-template-areas: 
            "header header header"
            "center center center"
            "footer footer footer";
    }
    .entry{
        flex-direction: column;
    }
    .title{
        font-weight: bold;
        font-size:x-large;
        
    }
    .productButton{
      font-size:medium;
      width:90%;
    }
    .loginlogo{
      height:55px;
      margin-right:0px;
      margin-top:10px;
    }
    .loginlogo2{
      height:45px;
      margin-left:10px;
      
    }
    .logo{
      height:55px;
      margin-top:10px;
    }
    .headertext{
      font-size:small;
      margin:2px;
    }
    .headertextbold{
      font-size:small;
      font-weight:bold;
      margin:2px;
    }
}

.mainDiv{
    background-color:var(--frame-color);
    grid-area: center;
    // background-color:#CACACA;
    padding:10px;
    
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-items:space-between;
}
.header{
    grid-area:header;
    padding:10px 0px 10px 0px ;
}
.panel{
    background-color:var(--frame-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}
.subtitleMain{
  font-size:large;
    margin:5px 0px 0px 0px;
    width:70%;
}
.backButton{
  color:#035891;
  background-color:transparent;
  text-decoration: underline;
  font-size:large;
  font-weight: bold;
  border:none;
  text-align:center;
  margin:0px;

}
.backButton:hover{
  cursor:pointer;
  background-color:transparent;
}

</style>
