<template>
    <div class="vertFlex" >
       
      
            <tallbutton toplabel="Sign Up" @click="signup()"/>
            <tallbutton toplabel="Make a Payment" @click="pay()"/>
            <tallbutton toplabel="Upload My Documents" @click="docs()"/> 
   
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'home',
    components:{
        tallbutton
    },
    mixins:[],
     props:{
        
 
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',

        ]),
        
    },
    data(){
        return{
 
        }
    },
    methods:{
         
        signup(){
            this.$emit('signup')
        },
        pay(){
            this.$emit('pay')
        },
        docs(){
            this.$emit('docs')
        }

    },
    mounted(){
    }
    

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
</style>