<template>
    <button :class="buttonclass" type="button"   :disabled="disabled">
        <div >
            <p class="buttontextbold">{{toplabel}}</p>
            <p class="buttontext">{{bottomlabel}}</p>
        </div>       
    </button>
</template>

<script>
export default {
    name:'tallbutton',
    props:{
        toplabel:{
            type:String,
            
        },
        bottomlabel:{
            type:String,
            
        },
        disabled:{
          type:Boolean,
          default:false
        },
        buttonclass:{
          type:String,
          default:"tallButton buttonprimary"
        }
      
    },
    
}
</script>

<style>
.buttontext{
  margin:0px;
}
.buttontextbold{
  margin:0px;
  font-weight: bold;
  font-size:x-large;
}
.tallButton{
    
    color:var(--light-text-color);
    
    border-radius:10px;
    width:250px;
    height:90px;
    padding:0px;
    font-size: large;
    margin:10px;

}

.buttonprimary{
  background-color:var(--button-color);
  border: none;
}
.buttonhighlight{
  background-color:var(--highlight-button-color);
}
.buttondisabled{
  background-color:rgb(92, 86, 86);
}
button:disabled{
  background-color:rgb(92, 86, 86);
}
button:hover{
    background-color:var(--highlight-button-color)

}
button:disabled:hover{
  background-color:rgb(92, 86, 86);
}
.buttonhighlightborder{
  background-color:var(--button-color);
  border:5px solid var(--highlight-button-color);
}
</style>