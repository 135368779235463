<template>
    <div class="vertFlex" >
        <p>Enter the Sponsor Code given to you by your school, or call/email {{phone}}, {{email}} to obtain it from your school</p>
        <div>
            <input ref="sponsor" v-model="code" class="sponsorcode"/>
            
        </div>
        <br>
        <div>
            <tallbutton toplabel="Submit Code" @click="checkcode()"/>
        </div>
        
    
        <p class="redtext">{{message}}</p>
        
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import tallbutton from '../controls/tallbutton.vue'
import datamethods from '../../services/datamethods.js'
export default {
    name:'promocode',
    components:{
        tallbutton
    },
    mixins:[datamethods],
     props:{
        
 
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',

        ]),
        
    },
    data(){
        return{
            phone:window.LOCATION_PHONE,
            email:window.LOCATION_EMAIL,
            code:null,
            message:null

        }
    },
    methods:{
         


        async checkcode(){
            this.message=null
       
            let req = {
                inString:this.code
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'checksponsorcode')

            //handle response
            if(response.Successful){
                this.$emit('approved')
            }else{
                this.message = "We don't recognize that code. Please check your code and try again."
            }
            this.pleaseWait=false;

        },

    },
    mounted(){
        this.code=null
        this.message=null
        this.$refs.sponsor.focus()
    }
    

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
.sponsorcode{
    width:200px;
}
.redtext{
    color:red;
}
</style>